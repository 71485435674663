import Utils from '../../utils';
import { sharedItemDataModel } from '../../model';

// export const signInReducer = (state: signInModel = new signInModel(), action: any) => {
//     switch (action.type) {
//         case Utils.ActionName.SIGN_IN:
//             return { ...state, ...action.payload };
//         default:
//             return state;
//     }
// };

export const sharedItemDataReducer = (state: sharedItemDataModel = new sharedItemDataModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ADD_SHARED_ITEM_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
