import Utils from '../../utils';
// import { batch } from "react-redux";
import axios from 'axios';

// export const increment = () => {
//     return (dispatch: Function) => {
//         dispatch({
//             type: Utils.ActionName.INC_COUNTER,
//         });
//     };
// };

// export const decrement = () => {
//     return (dispatch: Function) => {
//         dispatch({
//             type: Utils.ActionName.DEC_COUNTER,
//         });
//     };
// };

export const showSubadminList = (params: any, fromId?: any, sortType?: any, page?: any) => {
    return (dispatch: Function) => {
        // const { email, password } = values;
        // dispatch({
        //     type: Utils.ActionName.ADD_SUBADMIN_LIST,
        //     payload: '',
        // });
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(1, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        // const parameters = params;

        let parameters = '';

        if (fromId !== undefined && sortType === undefined) {
            if (fromId === '') {
                parameters = params;
            } else {
                parameters = params + `&fromId=${fromId}&page=${page}`;
            }
            // parameters= params+ `&fromId=${fromId}&page=${page}`;
        } else if (fromId === undefined && sortType !== undefined) {
            parameters = params + `&sortOrder=${sortType}`;
        } else if (fromId !== undefined && sortType !== undefined) {
            if (fromId === '') {
                if (sortType !== '') {
                    parameters = params + `&sortOrder=${sortType}`;
                } else {
                    parameters = params;
                }
            } else {
                parameters = params + `&sortOrder=${sortType}&fromId=${fromId}&page=${page}`;
            }
            // parameters= params+ `&sortOrder=${sortType}&fromId=${fromId}&page=${page}`;
        } else {
            parameters = params;
        }

        Utils.api.getApiCall(
            Utils.endPoints.subadmin,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // console.log('data listing');
                // console.log(data.data.users);
                let newData = data.data.users;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    // setTimeout(() => {
                    dispatch({
                        type: Utils.ActionName.ADD_SUBADMIN_LIST,
                        // payload: data,
                        payload: newData,
                    });

                    // --------
                    // if(newData.length===1){
                    //     // setTimeout(() => {
                    //         dispatch({
                    //             type: Utils.ActionName.CHANGE_SUBADMIN_CURRENT_ID,
                    //             // payload: data,
                    //             payload: newData[0].email,
                    //         });
                    //         dispatch({
                    //             type: Utils.ActionName.CHANGE_SUBADMIN_TRANSITION_PAGE,
                    //             // payload: data,
                    //             payload: 'prev',
                    //         });
                    //         dispatch({
                    //             type: Utils.ActionName.CHANGE_SUBADMIN_NEXT_PAGE,
                    //             // payload: data,
                    //             payload: false,
                    //         });
                    //     // }, 500);
                    //    ---------
                    // }
                    // }, 50);

                    // For pages
                    // setTimeout(() => {

                    // dispatch({
                    //     type: Utils.ActionName.ADD_USER_DATA,
                    //     payload: data,
                    // });
                    // dispatch({
                    //     type: Utils.ActionName.IS_LOADING,
                    //     payload: false,
                    // });
                    dispatch({
                        type: Utils.ActionName.CHANGE_SUBADMIN_NEXT_ID,
                        payload: data.data.nextId,
                    });
                    dispatch({
                        type: Utils.ActionName.CHANGE_SUBADMIN_PREV_ID,
                        payload: data.data.prevId,
                    });
                    // }

                    // if(sortType!==undefined){
                    // dispatch({
                    //     type: Utils.ActionName.CHANGE_SORT_TYPE,
                    //     payload: sortType,
                    // });
                    // }
                    //    let newDataLength= newData.length;

                    // console.log(newData[newDataLength-1].email);

                    // dispatch({
                    //     type: Utils.ActionName.CHANGE_CURRENT_ID,
                    //     payload: newData[newDataLength-1].email,
                    // });

                    dispatch({
                        type: Utils.ActionName.CHANGE_SUBADMIN_NEXT_PAGE,
                        payload: data.data.nextPage,
                    });

                    dispatch({
                        type: Utils.ActionName.CHANGE_SUBADMIN_PREV_PAGE,
                        payload: data.data.prevPage,
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });

                    // }, 500);
                } else {
                    // setSubmitting(false);
                    //   Utils.showAlert(2);
                    Utils.showAlert(1, data.message);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                // setSubmitting(false);
                let { data } = error;
                Utils.showAlert(1, data.message);
                // Utils.showAlert(2, data.message);
            },
        );
    };
};

export const changeSortSubadminTypeOrder = (sortType: any) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.CHANGE_SUBADMIN_SORT_TYPE,
            payload: sortType,
        });
    };
};

export const showUsersListSample = (params: any) => {
    return (dispatch: Function) => {
        // const { email, password } = values;
        dispatch({
            type: Utils.ActionName.ADD_USERS_LIST,
            payload: '',
        });
        const parameters = params;
        axios.get(`https://reqres.in/api/users${parameters}`).then(function (response) {
            console.log(response);
            let { data } = response;

            setTimeout(() => {
                dispatch({
                    type: Utils.ActionName.ADD_USERS_LIST,
                    payload: data,
                });
            }, 500);

            // For pages
            // setTimeout(() => {
            dispatch({
                type: Utils.ActionName.ADD_USER_DATA,
                payload: data,
            });
        });

        Utils.api.getApiCall(
            Utils.endPoints.users,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // console.log(data);

                // For users data
                // if (data.statusCode === Utils.constants.api_success_code.success) {
                setTimeout(() => {
                    dispatch({
                        type: Utils.ActionName.ADD_USERS_LIST,
                        payload: data,
                    });
                }, 500);

                // For pages
                // setTimeout(() => {
                dispatch({
                    type: Utils.ActionName.ADD_USER_DATA,
                    payload: data,
                });
                // }, 500);

                //   Utils.showAlert(2);
                // }
            },
            (error: any) => {
                // setSubmitting(false);
                // let { data } = error;
                // Utils.showAlert(2, data.message);
            },
        );
    };
};

export const deleteSubAdmin = (userId: any, currentId: any, sortType: any, page: any, users?: any) => {
    // let newNum: any = Math.random;
    // newNum = newNum * 10;
    return (dispatch: Function) => {
        const dataToSend = '';
        Utils.api.deleteApiCall(
            `admin/subadmin/${userId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                console.log(data);

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    // dispatch(showSubadminList(`?limit=50`));

                    if (users.length === 1) {
                        dispatch(showSubadminList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=${sortType}`));
                    } else {
                        dispatch(
                            showSubadminList(`?limit=${Utils.constants.page_limit.limit}`, currentId, sortType, page),
                        );
                    }

                    // Utils.showAlert(1, data.message);
                    Utils.showAlert(1, `Sub admin has been deleted successfully!`);

                    // sessionStorage.setItem('header_code', data.headerCode);

                    // for development api
                    // sessionStorage.setItem('access_token', data.data.token);
                    // sessionStorage.setItem('user_type', data.data.type);
                    // sessionStorage.setItem('email_id', data.data.email);

                    // sessionStorage.setItem('access_token', data.token);

                    // history.push(Utils.constants.paths.DASHBOARD);
                } else {
                    // setSubmitting(false);
                    Utils.showAlert(1, data.message);
                }
            },
            (error: any) => {
                // setSubmitting(false);
                let { data } = error;
                // let randomError = Math.random;
                console.log('error: ');
                console.log(error);

                dispatch({
                    type: Utils.ActionName.SIGN_IN,
                    payload: {
                        apiError: data.error,
                        showSnackbar: true,
                        editUserSnackbar: 'User email already exists',
                    },
                });
                // if (data.statusCode == Utils.constants.api_success_code.success) {

                if (data.statusCode === 400) {
                    Utils.showAlert(1, data.message);
                }

                // }
                // snackbar(data.error);
            },
        );
    };
};

export const changePageDashboard = (params: any) => {
    return (dispatch: Function) => {
        // const { email, password } = values;
        // dispatch({
        //     type: Utils.ActionName.ADD_USERS_LIST,
        //     payload: '',
        // });
        const parameters = params;
        Utils.api.getApiCall(
            Utils.endPoints.users,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // console.log(data);

                // if (data.statusCode === Utils.constants.api_success_code.success) {
                setTimeout(() => {
                    dispatch({
                        type: Utils.ActionName.ADD_USERS_LIST,
                        payload: data,
                    });
                }, 2000);
            },
            (error: any) => {
                // setSubmitting(false);
                // let { data } = error;
                // Utils.showAlert(2, data.message);
            },
        );
    };
};
