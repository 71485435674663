import React from 'react';
import {
    createStyles,
    Theme,
    DialogContent,
    Button,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    AppBar,
    Box,
    IconButton,
    Toolbar,
    makeStyles,
    // Link,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Logo from '../logo/index';
import './header.css';
// import Utils from '../../utils';
import NewSubAdminForm from './newSubAdminForm';
import AccountInfoForm from './accountInfoForm';
import { logoutAdmin } from './action';
import DialogAlert from '../../components/dialogue/index';
import SnackbarAlert from '../snackbar';
import { ReducersModel } from '../../model';
import { useSelector } from 'react-redux';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.main,
        },
        mainContainer: {},
        toolbar: {
            // minHeight: 64,
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        margin: {
            margin: theme.spacing(1),
        },
        menuFlex: {
            display: 'flex',
            alignItems: 'baseline',
        },
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        buttonWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            maxWidth: '354px',
            margin: '0 auto',
        },
        editFiend: {
            maxWidth: '354px',
            margin: '0 auto',
        },
        errorMail: {
            display: 'flex',
            justifyContent: 'center',
            color: 'darkslategrey',
        },
    }),
);

const theme = createMuiTheme({
    overrides: {
        MuiMenuItem: {
            root: {
                color: 'rgb(251, 242, 229)',
                fontSize: '16px',
                fontFamily: 'apercupromedium',
                lineHeight: '1.22',
            },
        },
    },
});
export default function AdminHeader({ props }: any) {
    const classes = styles();

    const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
    // const { history } = props;
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElMenu(null);
    };

    // For Edit Admin Info
    const [open, setOpen] = React.useState(false);
    const dialogClickOpen = () => {
        setOpen(true);
    };
    const dialogClose = () => {
        setOpen(false);
    };

    // For Add Admin
    const [openAddAdmin, setOpenAddAdmin] = React.useState(false);
    const dialogClickOpenAddAdmin = () => {
        setOpenAddAdmin(true);
    };
    const dialogCloseAddAdmin = () => {
        setOpenAddAdmin(false);
        // setSendInviteEmail('');
    };
    const goToManageUsers = () => {
        history.push('/manageUsers');
    };
    // Logout
    const [openLogout, setOpenLogout] = React.useState(false);

    const handleClickOpenLogout = () => {
        setOpenLogout(true);
    };

    const handleAlertCloseLogout = () => {
        setOpenLogout(false);
    };

    // Logout

    // snackbar
    const [messageSnackbarSubadmin, setMessageSnackbarSubadmin] = React.useState(
        'Email invitation has been sent to the user.',
    );
    const [openSnackbarSubadmin, setOpenSnackbarSubadmin] = React.useState(false);

    const handleClickSnackbarSubadmin = (message: any) => {
        if (message) {
            console.log('message:' + message);
            setMessageSnackbarSubadmin(message);
        }
        setOpenSnackbarSubadmin(true);
    };

    const handleCloseSnackbarSubadmin = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarSubadmin(false);
    };
    ////
    const [openSnackbarAccountInfo, setOpenSnackbarAccountInfo] = React.useState(false);

    const handleClickSnackbarAccountInfo = () => {
        setOpenSnackbarAccountInfo(true);
    };

    const { email } = useSelector((state: ReducersModel) => state.signInReducer);

    const handleCloseSnackbarAccountInfo = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbarAccountInfo(false);
    };
    // snackbar

    return (
        <AppBar className="header">
            <Toolbar className={classes.toolbar}>
                <Logo />

                <Box ml={2}>
                    {/* <div> */}
                    <Box className={classes.menuFlex}>
                        <Typography
                            variant="h6"
                            color="inherit"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className="username"
                        >
                            {email === '' ? sessionStorage.email_id : email}
                            {/* User Name */}
                        </Typography>
                        <IconButton aria-label="menu" className={classes.margin} size="small" onClick={handleClick}>
                            {/* <ArrowDropDownIcon color="inherit" /> */}
                            <img src="caret-down.png" alt="" />
                        </IconButton>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <Menu
                            // id="simple-menu"
                            anchorEl={anchorElMenu}
                            keepMounted
                            open={Boolean(anchorElMenu)}
                            onClose={handleClose}
                            className="account_dropdown"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: {
                                    backgroundColor: 'rgb(75, 48, 88)',
                                    minWidth: '199px',
                                },
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    dialogClickOpen();
                                }}
                            >
                                Account Info
                            </MenuItem>

                            {/* Only admin can add other sub-admins */}
                            {sessionStorage.user_type === 'admin' ? (
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        dialogClickOpenAddAdmin();
                                    }}
                                >
                                    Add User
                                </MenuItem>
                            ) : (
                                <span></span>
                            )}

                            {/* Only admin can manage other sub-admins */}
                            {sessionStorage.user_type === 'admin' ? (
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        goToManageUsers();
                                    }}
                                >
                                    Manage Users
                                </MenuItem>
                            ) : (
                                <span></span>
                            )}
                            <MenuItem
                                onClick={() => {
                                    handleClose();
                                    handleClickOpenLogout();
                                }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </ThemeProvider>
                </Box>
            </Toolbar>
            <div>
                <DialogAlert open={open} onClose={dialogClose}>
                    <AccountInfoForm handleClose={dialogClose} openSnackbar={handleClickSnackbarAccountInfo} />
                </DialogAlert>
            </div>

            <div>
                <DialogAlert open={openAddAdmin} onClose={dialogCloseAddAdmin}>
                    <NewSubAdminForm
                        onCloseBox={dialogCloseAddAdmin}
                        openSnackbar={(message: any) => handleClickSnackbarSubadmin(message)}
                    />
                </DialogAlert>
            </div>

            <Dialog
                open={openLogout}
                onClose={handleAlertCloseLogout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="text_center"
            >
                <DialogTitle id="alert-dialog-title">{'Logout'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog_btn_center">
                    <Button
                        onClick={() => {
                            handleAlertCloseLogout();
                            logoutAdmin();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleAlertCloseLogout} variant="contained" color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarAlert
                message={messageSnackbarSubadmin}
                open={openSnackbarSubadmin}
                handleClose={handleCloseSnackbarSubadmin}
            />

            <SnackbarAlert
                message={'Your credentials has been updated successfully!'}
                open={openSnackbarAccountInfo}
                handleClose={handleCloseSnackbarAccountInfo}
            />
        </AppBar>
    );
}
