import React from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    // Container,
    TextField,
    FormHelperText,
    Box,
    Button,
    Typography,
    // Snackbar,
    // IconButton,
} from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';

import { inviteSubAdmin } from './action';
import { useDispatch } from 'react-redux';

import Schema from '../../schema/index';
import { Formik } from 'formik';
import './header.css';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        apiError: {
            backgroundColor: 'transparent',
        },
        editFiend: {
            maxWidth: '354px',
            margin: '0 auto',
        },
        errorMail: {
            display: 'flex',
            justifyContent: 'center',
            color: 'darkslategrey',
        },
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        buttonWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            maxWidth: '354px',
            margin: '0 auto',
        },
    }),
);

export default function AddNewSubAdmin(props: any) {
    const classes = styles();
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <Typography align="center" variant="h5" className="sub_heading">
                Add an Admin User
            </Typography>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={Schema.AddAdminSchema}
                onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
                    // try {
                    // alert(JSON.stringify(values, null, 2));
                    dispatch(inviteSubAdmin(values, setSubmitting, props));
                    // console.log('Helllooo from new admin submit');

                    // setTimeout(() => {
                    //     props.onCloseBox();
                    // }, 500);
                    // // setOpenSnackbar(true);
                    // props.openSnackbar();
                    // } catch (error) {
                    //     // const message = (error.response && error.response.data.message) || 'Something went wrong';

                    //     setStatus({ success: false });
                    //     // setErrors({ submit: message });
                    //     setSubmitting(false);
                    //     console.log('Helllooo error from new admin submit');
                    // }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <React.Fragment>
                        <form className={classes.root} onSubmit={handleSubmit}>
                            <Box className={classes.editFiend}>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    // autoFocus
                                    helperText={touched.email && errors.email}
                                    placeholder="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    className="bgWhiteInp newSubAdmin"
                                />

                                {/* <div className={classes.errorMail}>{sendInviteEmailError}</div> */}
                            </Box>

                            <Box className={classes.buttonWrap}>
                                <Button
                                    onClick={() => {
                                        // dialogCloseAddAdmin();
                                        // sendInvite();
                                        // console.log('hiii');
                                    }}
                                    className="small_btn"
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Send Invite
                                </Button>
                                <Box mt={3}>
                                    <FormHelperText className={classes.apiError} error>
                                        {/* {apiError} */}
                                    </FormHelperText>
                                </Box>
                            </Box>
                        </form>
                    </React.Fragment>
                )}
            </Formik>
        </React.Fragment>
    );
}
