import React from 'react';
import { makeStyles, createStyles, Theme, Box, Container } from '@material-ui/core';
import './manageUsers.css';

// import Pagination from '@material-ui/lab/Pagination';
// import { showUsersList } from './action';
// import { showUsersListSample } from './action';
import { useSelector, useDispatch } from 'react-redux';
import NoDataFound from '../../components/noDataFound/index';
import { ReducersModel } from '../../model';
import Spinner from '../../components/spinner';
// import { useridToDelete } from './action';
import DeleteSubAdmin from '../../components/deleteSubAdmin';
import Utils from '../../utils';
import { showSubadminList } from './action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            margin: '20px auto',
            padding: '10px',
            border: '1px solid #eee',
        },
        paginationBox: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '& .Mui-selected': {
                backgroundColor: 'rgb(75, 48, 88)',
                color: 'rgb(251, 242, 229)!important',
                '&:hover': {
                    backgroundColor: 'rgb(75, 48, 88, 0.8)!important',
                },
            },

            '& .MuiPagination-ul': {
                '&> li': {
                    '& .MuiPaginationItem-root': {
                        border: '1px solid rgba(75, 48, 88, 0.53)',
                        color: 'rgb(75, 48, 88)',
                        margin: 0,
                        marginRight: '13px',
                    },
                },
                // '&> li:first-child': {
                //     '& .MuiPaginationItem-root': {
                //         border: 'none',
                //         color: 'rgb(75, 48, 88)',
                //     },
                // },
                // '&> li:last-child': {
                //     '& .MuiPaginationItem-root': {
                //         border: 'none',
                //         color: 'rgb(75, 48, 88)',
                //     },
                // },
            },
        },

        pagination: {
            display: 'flex',
            marginTop: '5px',
            marginBottom: '30px',
            alignItems: 'center',
            justifyContent: 'center',
            '&>div': {
                margin: '0 8px',
            },
        },
        leftButton: {
            width: '21px',
            height: '19px',
            marginRight: '13px',
            cursor: 'pointer',
        },
        rightButton: {
            width: '21px',
            height: '19px',
            cursor: 'pointer',
        },
        usersList: {
            minHeight: '580px',
        },
        delete: {
            width: '110px',
        },
        userEmail: {
            wordBreak: 'break-all',
        },
    }),
);

export default function UsersList({ history, className, ...props }: any) {
    const dispatch = useDispatch();

    // const { totalPages } = useSelector((state: ReducersModel) => state.usersListReducer);
    const { isLoading } = useSelector((state: ReducersModel) => state.globalLoaderReducer);
    const { nextId, sortType, nextPage, prevPage, prevId, users } = useSelector(
        (state: ReducersModel) => state.subadminListReducer,
    );
    // for Pagination
    // const [page, setPage] = React.useState(1);
    // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //     setPage(value);
    // };
    //

    // const previousPage = () => {
    //     if (page !== 1) {
    //         setPage(page - 1);
    //     }
    // };
    // const nextPage = () => {
    //     if (page < totalPages.total_pages) {
    //         setPage(page + 1);
    //     }
    // };

    const turnNextPage = () => {
        if (nextPage === true) {
            if (users !== null) {
                dispatch({
                    type: Utils.ActionName.CHANGE_SUBADMIN_CURRENT_ID,
                    payload: users[users.length - 1].email,
                });
                dispatch({
                    type: Utils.ActionName.CHANGE_SUBADMIN_TRANSITION_PAGE,
                    payload: 'next',
                });
            }
            // params: any, fromId?: any, sortType?:any, page?: any
            dispatch(showSubadminList(`?limit=${Utils.constants.page_limit.limit}`, nextId, sortType, 'next'));

            // dispatch({
            //     type: Utils.ActionName.CHANGE_REFRESH_ID,
            //     payload:
            // })
        }
    };

    const turnPrevPage = () => {
        if (prevPage === true) {
            // params: any, fromId?: any, sortType?:any, page?: any
            dispatch(showSubadminList(`?limit=${Utils.constants.page_limit.limit}`, prevId, sortType, 'prev'));

            if (users !== null) {
                dispatch({
                    type: Utils.ActionName.CHANGE_SUBADMIN_CURRENT_ID,
                    payload: users[0].email,
                });
                dispatch({
                    type: Utils.ActionName.CHANGE_SUBADMIN_TRANSITION_PAGE,
                    payload: 'prev',
                });
            }
        }
    };

    const classes = styles();

    const listUsers = () => {
        if (isLoading === false) {
            if (props.users && props.users.length !== 0) {
                return props.users.map((user: any, index: any) => {
                    return (
                        <Container key={index} className="listBox">
                            <Box className={`item ${classes.userEmail}`}>{user.email}</Box>
                            <Box className="item">sub-admin</Box>
                            <Box className={classes.delete}>
                                <DeleteSubAdmin user={user} />
                            </Box>
                        </Container>
                    );
                });
            } else {
                return <NoDataFound />;
            }
        } else {
            return <Spinner />;
        }
    };

    return (
        <Box>
            <Box className={classes.usersList}>{listUsers()}</Box>

            <div className={classes.pagination}>
                <div>
                    <img
                        onClick={turnPrevPage}
                        src={'arrow-left.png'}
                        alt={'left-arrow'}
                        className={classes.leftButton}
                        style={prevPage === false ? { opacity: 0.35, cursor: 'not-allowed' } : {}}
                    />
                </div>
                {/* <div className={classes.paginationBox}>
                    <Pagination
                        count={totalPages.total_pages}
                        page={page}
                        onChange={handleChange}
                        hidePrevButton
                        hideNextButton
                    />
                </div> */}
                <div>
                    <img
                        onClick={turnNextPage}
                        src={'arrow-right.png'}
                        alt={'right-arrow'}
                        className={classes.rightButton}
                        style={nextPage === false ? { opacity: 0.35, cursor: 'not-allowed' } : {}}
                    />
                </div>
            </div>
        </Box>
    );
}
