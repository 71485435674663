import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogContent, makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
    }),
);

const Dialogue = ({ children, ...props }: any) => {
    const classes = styles();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            PaperProps={{
                style: {
                    backgroundImage: 'linear-gradient(to top, rgba(75, 48, 88, 0.5), rgba(255, 241, 229, 0))',
                    width: '753px',
                    padding: '79.5px 0',
                    borderRadius: '11px',
                },
            }}
        >
            <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default Dialogue;
