import React from 'react';
import './App.css';
import Router from './router/index';
import { toast } from 'react-toastify';
import {
    createStyles,
    // jssPreset,
    makeStyles,
    // StylesProvider,
    ThemeProvider,
} from '@material-ui/core';
import { theme } from './components/theme/index';

toast.configure({
    autoClose: 3000,
    draggable: false,
    newestOnTop: true,
    position: 'bottom-center',
    closeOnClick: true,
});

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                width: '100%',
                backgroundColor: 'rgb(255, 251, 244)',
                fontFamily: 'apercuproregular',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
        },
    }),
);

function App() {
    useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    );
}

export default App;
