export default {
    login: 'admin/login',
    // users: 'users',
    users: 'admin/user',
    subadmin: 'admin/subadmin',
    signUp: 'admin/subadmin-register',
    inviteSubAdmin: 'admin/add-subadmin',
    editUserInfo: 'admin/user/',
    editAdmin: 'admin/profile',
    refreshAdmin: 'token/refresh',
};
