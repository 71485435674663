import React, { lazy, Suspense } from 'react';
import PrivateRoute from './private';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { fade } from '@material-ui/core/styles/colorManipulator';

// import { Redirect } from 'react-router-dom';
// import AdminLayout from '../components/adminLayout/index';
// import { CircularProgress } from '@material-ui/core';
import AuthLayout from '../components/authLayout/index';
import SharedCrateLayout from '../components/authLayout/sharedCrateLayout';
import Utils from '../utils';

import Loading from '../components/loading/index';

const Login = lazy(() => import('../screens/login/index'));
const Dashboard = lazy(() => import('../screens/dashboard'));
const NewAdmin = lazy(() => import('../screens/newAdminLogin/index'));
const ManageUsers = lazy(() => import('../screens/manageUsers/index'));
const ResetPassword = lazy(() => import('../screens/resetUserPassword/index'));
const NotFound = lazy(() => import('../screens/pageNotFound/index'));
const SharedCrate = lazy(() => import('../screens/sharedCrate/index'));
const ViewAllMedia = lazy(() => import('../screens/viewAllMedia'));
const ViewAllNotes = lazy(() => import('../screens/viewAllNotes'));
const ViewAllUrl = lazy(() => import('../screens/viewAllUrl'));
const SharedItem = lazy(() => import('../screens/sharedItem/index'));

/**
 * Root router component
 * @param props parameter for Router function
 */
const Router: React.FC = () => {
    // const pathname = Utils.constants.paths;

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                {Utils.constants.getUserIdToken() ? (
                                    //  <InnerContainer {...props} >
                                    <Redirect to="/dashboard" />
                                ) : (
                                    // </InnerContainer>
                                    <AuthLayout {...props}>
                                        <Login {...props} />
                                    </AuthLayout>
                                )}
                            </Suspense>
                        )}
                    />

                    {/* <Route
                        exact
                        path="/dashboard"
                        render={(props: any) => (
                            <Suspense
                                fallback={
                                    <div>
                                        <span>Loading....</span>
                                    </div>
                                }
                            >
                                <AdminLayout {...props}>
                                    <Dashboard {...props} />
                                </AdminLayout>
                            </Suspense>
                        )}
                    /> */}
                    <PrivateRoute path={'/dashboard'} component={Dashboard} />

                    <Route
                        exact
                        path="/newAdmin"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <AuthLayout>
                                    <NewAdmin {...props} />
                                </AuthLayout>
                            </Suspense>
                        )}
                    />

                    {/* <Route
                        exact
                        path="/manageUsers"
                        render={(props: any) => (
                            <Suspense
                                fallback={
                                    <div>
                                        <span>Loading....</span>
                                    </div>
                                }
                            >
                                <AdminLayout {...props}>
                                    <ManageUsers {...props} />
                                </AdminLayout>
                            </Suspense>
                        )}
                    /> */}

                    <PrivateRoute path={'/manageUsers'} component={ManageUsers} />

                    <Route
                        exact
                        path="/resetUserPassword"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <AuthLayout>
                                    <ResetPassword {...props} />
                                </AuthLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        exact
                        path="/sharedCrate"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <SharedCrateLayout>
                                    <SharedCrate {...props} />
                                </SharedCrateLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        exact
                        path="/sharedItem"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <SharedCrateLayout>
                                    <SharedItem {...props} />
                                </SharedCrateLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        exact
                        path="/viewAllMedia"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <SharedCrateLayout>
                                    <ViewAllMedia {...props} />
                                </SharedCrateLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        exact
                        path="/viewAllNotes"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <SharedCrateLayout>
                                    <ViewAllNotes {...props} />
                                </SharedCrateLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        exact
                        path="/viewAllUrl"
                        render={(props: any) => (
                            <Suspense fallback={<Loading />}>
                                <SharedCrateLayout>
                                    <ViewAllUrl {...props} />
                                </SharedCrateLayout>
                            </Suspense>
                        )}
                    />

                    <Route
                        component={(props: object) => (
                            <Suspense fallback={<Loading />}>
                                <AuthLayout>
                                    <NotFound {...props} />
                                </AuthLayout>
                            </Suspense>
                        )}
                    />
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default Router;
