import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const commonErr = 'OOPS! something went wrong!';

const showAlert = (type: number, message: string = commonErr) => {
    if (toast.error === undefined) {
        toast.configure({
            autoClose: 2000,
            draggable: false,
            newestOnTop: true,
            position: 'bottom-center',
        });
    }
    switch (type) {
        case 1:
            toast.dark(message);
            break;
        case 2:
            toast.dark(message);
            break;
        case 3:
            toast.dark(message);
            break;
    }
};

export default showAlert;
