import Utils from '../../utils';
import { subadminListModel } from '../../model';

export const subadminListReducer = (state: subadminListModel = new subadminListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ADD_SUBADMIN_LIST: {
            return {
                ...state,
                users: action.payload,
            };
        }

        case Utils.ActionName.CHANGE_SUBADMIN_SORT_TYPE: {
            return {
                ...state,
                sortType: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_CURRENT_ID: {
            return {
                ...state,
                currentId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_NEXT_ID: {
            return {
                ...state,
                nextId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_PREV_ID: {
            return {
                ...state,
                prevId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_NEXT_PAGE: {
            return {
                ...state,
                nextPage: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_PREV_PAGE: {
            return {
                ...state,
                prevPage: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_REFRESH_ID: {
            return {
                ...state,
                refreshId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SUBADMIN_TRANSITION_PAGE: {
            return {
                ...state,
                transitionPage: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
