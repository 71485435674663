import React from 'react';
import {
    //  makeStyles, createStyles,
    // Theme,
    Container,
} from '@material-ui/core';
import Logo from '../logo/index';
import './header.css';
// const styles = makeStyles((theme: Theme) =>
//     createStyles({

//     }),
// );

export default function SharedCrateHeader(history: any) {
    // const classes = styles();

    return (
        <Container className="header">
            <Logo history={history} />
        </Container>
    );
}
