import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Grid,
    Breadcrumbs,
    Typography,
    SvgIcon,
    Button,
    Menu,
    MenuItem,
    Box,
    // Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownUp from '@material-ui/icons/ArrowDropUp';
import { Helmet } from 'react-helmet';

// import Pagination from '@material-ui/lab/Pagination';
// import { useSelector, useDispatch } from 'react-redux';

import { ReducersModel } from '../../model';
import UsersList from './usersList';

import { showUsersList, changeSortTypeOrder } from './action';
import { useDispatch, useSelector } from 'react-redux';
import Utils from '../../utils';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            body: {
                backgroundColor: 'rgb(255, 251, 244)',
            },
        },
        mainContainer: {},
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            margin: '20px auto',
            padding: '10px',
            border: '1px solid #eee',
        },
        root: {
            boxShadow: 'none',
        },
        search: {
            backgroundColor: 'rgba(36, 31, 37, 0.14)',
            minWidth: '320px',
        },
        textSizeLarge: {
            fontSize: '20px',
        },
        sortBox: {
            marginTop: '20px',
        },
        closeIcon: {
            cursor: 'pointer',
        },
    }),
);

export default function Dashboard({ history, className, ...rest }: any) {
    // const { counter } = useSelector((state: ReducersModal) => state.counterReducer);

    const dispatch = useDispatch();
    const debounceCount = useRef(0);
    const { users, searchQuery, sortType } = useSelector((state: ReducersModel) => state.usersListReducer);
    // const { isLoading } = useSelector((state: ReducersModel) => state.usersListReducer);
    // const incCount = () => {
    //     dispatch(increment());
    // };

    // const decCount = () => {
    //     dispatch(decrement());

    useEffect(() => {
        // passed parameters limit, nextId, and sortOrder
        dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}`));
        // if(sortTypeValue==="asc"){
        dispatch({
            type: Utils.ActionName.CHANGE_SORT_TYPE,
            payload: 'asc',
        });
        dispatch({ type: Utils.ActionName.CHANGE_CURRENT_ID, payload: '' });
        // }
        // dispatch(showUsersListSample(`?page=${page}`));
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Sorting state
    const [sortTypeValue, setSortTypeValue] = useState('A-Z');
    const changeSortType = (event: any) => {
        setSortTypeValue(event.target.innerText);
        handleClose();
    };

    const api = () =>
        new Promise((res) => {
            setTimeout(() => {
                res(1);
            }, 300);
        });

    const [searchText, setSearchText] = useState('');

    const searchUser = (event: any) => {
        const searchQuery = event.currentTarget.value;

        setSearchText(event.currentTarget.value);
        debounceCount.current += 1;
        const localDebounceCount = debounceCount.current;
        api().then((d) => {
            if (localDebounceCount !== debounceCount.current) {
                console.log('stoped');
                return;
            }
            //   console.log("i worked");

            if (searchQuery !== '') {
                dispatch({
                    type: Utils.ActionName.CHANGE_SEARCH_QUERY,
                    payload: searchQuery,
                });

                // dispatch(showUsersListBySearch(`?limit=${Utils.constants.page_limit.limit}`, `&search=${searchQuery}`));
                dispatch(
                    showUsersList(
                        `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}&sortOrder=${sortType}`,
                    ),
                );
            } else {
                dispatch({ type: Utils.ActionName.CHANGE_SEARCH_QUERY, payload: '' });
                dispatch({ type: Utils.ActionName.CHANGE_CURRENT_ID, payload: '' });
                dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=${sortType}`));
            }
        });
    };

    const deleteSearch = () => {
        setSearchText('');
        dispatch({
            type: Utils.ActionName.CHANGE_SEARCH_QUERY,
            payload: '',
        });
        dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=${sortType}`));
    };

    const classes = styles();

    return (
        <React.Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <Box className="wrapper">
                <Grid className={classes.root} container justify="space-between" spacing={2} {...rest}>
                    <Grid item>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        ></Breadcrumbs>
                        <Typography color="textPrimary" className="sub_heading">
                            User Dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className="searchInp">
                            <input type="text" placeholder="Search" onChange={searchUser} value={searchText} />
                            <div className="searchIcon">
                                <SvgIcon color="action">
                                    {searchText === '' ? (
                                        <SearchIcon />
                                    ) : (
                                        <CloseIcon onClick={deleteSearch} className={classes.closeIcon} />
                                    )}
                                </SvgIcon>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    className={`${classes.root} ${classes.sortBox}`}
                    container
                    justify="space-between"
                    spacing={3}
                    {...rest}
                >
                    <Grid item>
                        <Button
                            aria-haspopup="true"
                            onClick={handleClick}
                            size="large"
                            variant="text"
                            className="sorting"
                            endIcon={
                                sortTypeValue === 'A-Z' ? (
                                    <ArrowDropDownDown color="primary" style={{ fontSize: 35 }} />
                                ) : (
                                    <ArrowDropDownUp color="primary" style={{ fontSize: 35 }} />
                                )
                            }
                        >
                            {sortTypeValue}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                value={'A-Z'}
                                onClick={(e: any) => {
                                    changeSortType(e);
                                    dispatch(changeSortTypeOrder('asc'));
                                    if (searchQuery === '') {
                                        dispatch(
                                            showUsersList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=asc`),
                                        );
                                    } else {
                                        dispatch(
                                            showUsersList(
                                                `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}&sortOrder=asc`,
                                            ),
                                        );
                                    }
                                }}
                            >
                                A-Z
                            </MenuItem>
                            <MenuItem
                                value={'Z-A'}
                                onClick={(e: any) => {
                                    changeSortType(e);
                                    dispatch(changeSortTypeOrder('desc'));

                                    if (searchQuery === '') {
                                        dispatch(
                                            showUsersList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=desc`),
                                        );
                                    } else {
                                        dispatch(
                                            showUsersList(
                                                `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}&sortOrder=desc`,
                                            ),
                                        );
                                    }
                                }}
                            >
                                Z-A
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <UsersList users={users} />
                </Box>
            </Box>
        </React.Fragment>
    );
}
