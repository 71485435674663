import Utils from '../../utils';
// import { batch } from "react-redux";
import axios from 'axios';

// export const increment = () => {
//     return (dispatch: Function) => {
//         dispatch({
//             type: Utils.ActionName.INC_COUNTER,
//         });
//     };
// };

// export const decrement = () => {
//     return (dispatch: Function) => {
//         dispatch({
//             type: Utils.ActionName.DEC_COUNTER,
//         });
//     };
// };

export const showUsersList = (params: any, fromId?: any, sortType?: any, page?: any) => {
    // console.log(nextId);
    return (dispatch: Function) => {
        if (!navigator.onLine) {
            //check if user is online or not
            Utils.showAlert(1, 'Please check your internet connection!');
            dispatch({
                type: Utils.ActionName.IS_LOADING,
                payload: false,
            });
            return;
        }
        // console.log('show user.....');
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });

        let parameters = '';

        if (fromId !== undefined && sortType === undefined) {
            if (fromId === '') {
                parameters = params;
            } else {
                parameters = params + `&fromId=${fromId}&page=${page}`;
            }
        } else if (fromId === undefined && sortType !== undefined) {
            parameters = params + `&sortOrder=${sortType}`;
        } else if (fromId !== undefined && sortType !== undefined) {
            if (fromId === '') {
                if (sortType !== '') {
                    parameters = params + `&sortOrder=${sortType}`;
                } else {
                    parameters = params;
                }
            } else {
                parameters = params + `&sortOrder=${sortType}&fromId=${fromId}&page=${page}`;
            }
        } else {
            parameters = params;
        }

        Utils.api.getApiCall(
            Utils.endPoints.users,
            parameters,
            (respData: any) => {
                let { data } = respData;
                // console.log('data listing');
                // console.log(data.data.users);
                let newData = data.data.users;

                // For users data
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    dispatch({
                        type: Utils.ActionName.ADD_USERS_LIST,
                        payload: newData,
                    });
                    // if(nextId!==undefined){
                    dispatch({
                        type: Utils.ActionName.CHANGE_NEXT_ID,
                        payload: data.data.nextId,
                    });
                    dispatch({
                        type: Utils.ActionName.CHANGE_PREV_ID,
                        payload: data.data.prevId,
                    });
                    // }

                    // if(sortType!==undefined){
                    // dispatch({
                    //     type: Utils.ActionName.CHANGE_SORT_TYPE,
                    //     payload: sortType,
                    // });
                    // }
                    //    let newDataLength= newData.length;

                    // console.log(newData[newDataLength-1].email);

                    // dispatch({
                    //     type: Utils.ActionName.CHANGE_CURRENT_ID,
                    //     payload: newData[newDataLength-1].email,
                    // });

                    dispatch({
                        type: Utils.ActionName.CHANGE_NEXT_PAGE,
                        payload: data.data.nextPage,
                    });

                    dispatch({
                        type: Utils.ActionName.CHANGE_PREV_PAGE,
                        payload: data.data.prevPage,
                    });

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    // setSubmitting(false);
                    //   Utils.showAlert(2);
                    Utils.showAlert(1, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                // setSubmitting(false);
                let { data } = error;
                Utils.showAlert(1, data.message);
                // Utils.showAlert(2, data.message);

                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });
            },
        );
    };
};

export const changeSortTypeOrder = (sortType: any) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.CHANGE_SORT_TYPE,
            payload: sortType,
        });
    };
};

// only for sample api use will remove this later
export const showUsersListSample = (params: any) => {
    return (dispatch: Function) => {
        // const { email, password } = values;
        // dispatch({
        //     type: Utils.ActionName.ADD_USERS_LIST,
        //     payload: '',
        // });
        const parameters = params;
        axios.get(`https://reqres.in/api/users${parameters}`).then(function (response) {
            console.log(response);
            let { data } = response;

            setTimeout(() => {
                dispatch({
                    type: Utils.ActionName.ADD_USERS_LIST,
                    payload: data,
                });
            }, 500);

            dispatch({
                type: Utils.ActionName.ADD_USER_DATA,
                payload: data,
            });
        });

        Utils.api.getApiCall(
            Utils.endPoints.users,
            parameters,
            (respData: any) => {
                let { data } = respData;

                // if (data.statusCode === Utils.constants.api_success_code.success) {
                setTimeout(() => {
                    dispatch({
                        type: Utils.ActionName.ADD_USERS_LIST,
                        payload: data,
                    });
                }, 500);

                // For pages
                // setTimeout(() => {
                dispatch({
                    type: Utils.ActionName.ADD_USER_DATA,
                    payload: data,
                });
                // }, 500);
            },
            (error: any) => {
                // setSubmitting(false);
                // let { data } = error;
                // Utils.showAlert(2, data.message);
            },
        );
    };
};

export const editUserInformation = (
    values: any,
    setSubmitting: any,
    history: any,
    currentId: any,
    sortType: any,
    page: any,
    searchQuery?: any,
    users?: any,
) => {
    return (dispatch: Function) => {
        dispatch({
            type: Utils.ActionName.IS_LOADING,
            payload: true,
        });
        const { email, password } = values;
        const dataToSend = {
            email,
            password,
        };
        Utils.api.putApiCall(
            `admin/user/${values.userId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, 'User credentials has been updated successfully!');

                    // if(currentId===""){
                    //     if(searchQuery===""){
                    //         dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}`, undefined, sortType, undefined));
                    //     }else{
                    //         dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}`, undefined, sortType, undefined));
                    //     }

                    // }else{
                    //     if(searchQuery===""){
                    //         dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}`, currentId, sortType, page));
                    //     }else{
                    //         dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}`, currentId, sortType, page));
                    //     }

                    // }

                    let indexOfUser = users.findIndex((x: any) => x.user_id === values.userId);
                    // console.log(indexOfUser);
                    let newUserList = [...users];
                    newUserList[indexOfUser].email = values.email;
                    dispatch({
                        type: Utils.ActionName.ADD_USERS_LIST,
                        payload: newUserList,
                    });

                    setSubmitting(false);
                    // sessionStorage.setItem('header_code', data.headerCode);
                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                } else {
                    setSubmitting(false);
                    Utils.showAlert(1, data.message);

                    dispatch({
                        type: Utils.ActionName.IS_LOADING,
                        payload: false,
                    });
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;

                // if (data.statusCode == Utils.constants.api_success_code.success) {
                if (data.statusCode === 400) {
                    Utils.showAlert(1, data.message);
                }
                dispatch({
                    type: Utils.ActionName.IS_LOADING,
                    payload: false,
                });

                // }
            },
        );
    };
};

// params: any, fromId?: any, sortType?:any, page?: any

export const activateAndSuspend = (
    task: any,
    userId: any,
    currentId: any,
    sortType: any,
    page: any,
    searchQuery: any,
) => {
    // let newNum: any = Math.random;
    // newNum = newNum * 10;
    return (dispatch: Function) => {
        // const { email, password } = values;
        const dataToSend = {};
        Utils.api.putApiCall(
            `admin/${task}/${userId}`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                console.log(data);

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    if (searchQuery === '') {
                        dispatch(
                            showUsersList(`?limit=${Utils.constants.page_limit.limit}`, currentId, sortType, page),
                        );
                    } else {
                        dispatch(
                            showUsersList(
                                `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}`,
                                currentId,
                                sortType,
                                page,
                            ),
                        );
                    }

                    Utils.showAlert(
                        1,
                        `User has been ${task === 'activate' ? 'activated' : 'suspended'} successfully!`,
                    );

                    // setSubmitting(false);
                } else {
                    // setSubmitting(false);

                    Utils.showAlert(1, data.message);
                }
            },
            (error: any) => {
                // setSubmitting(false);
                let { data } = error;

                // if (data.statusCode == Utils.constants.api_success_code.success) {
                if (data.statusCode === 400) {
                    Utils.showAlert(1, data.message);
                }
            },
        );
    };
};
