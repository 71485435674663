import Utils from '../utils';
import { logoutAdmin } from '../components/header/action';

// import { useTranslation } from "react-i18next";
// const { t } = useTranslation();
/**
 * function to check if code matches to user invalid.
 * @param data
 */

const checkUserValidation = (data: any) => {
    if (data) {
        const { statusCode } = data,
            { sessionExpired, unauthorized, accessDenied } = Utils.constants.api_error_code;
        if (statusCode) {
            return statusCode === sessionExpired || statusCode === unauthorized || statusCode === accessDenied;
        }
        return false;
    }
    return false;
};

// when user session expires and need to get a new access_token using refresh_token
export const getAccessTokenUsingRefreshToken = () => {
    console.log('getting access_token by refresh_token');
    // return (dispatch: Function) => {
    if (!navigator.onLine) {
        //check if user is online or not
        Utils.showAlert(1, 'Please check your internet connection!');
        // setSubmitting(false);
        return;
    }

    if (sessionStorage.getItem('refresh_token') !== null) {
        // const { email, password } = values;
        const dataToSend = {
            refresh_token: sessionStorage.getItem('refresh_token'),
        };
        // console.log(dataToSend);
        Utils.api.postApiCall(
            Utils.endPoints.refreshAdmin,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    // setSubmitting(false);
                    sessionStorage.setItem('header_code', data.headerCode);
                    sessionStorage.setItem('access_token', data.data.access_token);
                    sessionStorage.setItem('refresh_token', data.data.refresh_token);
                    // window.location.replace(window.location.origin);
                    Utils.constants.setAuthorizationToken(data.data.access_token);
                    // changed to logout if token not exists
                    window.location.reload();
                    // logoutAdmin();

                    // history.push(Utils.constants.paths.DASHBOARD);
                } else {
                    // setSubmitting(false);
                    // Utils.showAlert(1, data.message);
                    logoutAdmin();
                }
            },
            (error: any) => {
                // setSubmitting(false);
                // let { data } = error;
                // Utils.showAlert(1, data.message);
                logoutAdmin();
            },
        );
        // };
    } else {
        logoutAdmin();
    }
};

/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    console.log({ endPoint, params, successCallback });
    Utils.constants.axios
        .post(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            console.log('API Call error', error);
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    console.log('session expired for user');
                    // Utils.showAlert(1, data.message);
                    setTimeout(() => {
                        // logOutApiCall();
                    }, 1000);
                    errorCallback(error.response);
                } else {
                    console.log('response: ', error.response);
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (endPoint: string, params: string = '', successCallback: Function, errorCallback: Function) => {
    console.log({ endPoint, params });
    Utils.constants.axios
        .get(Utils.constants.apiUrl + endPoint + params, {})
        .then((response: any) => {
            console.log(response);
            successCallback(response);
        })
        .catch((error: any) => {
            console.log('API Call error', error);
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;

                if (checkUserValidation(data)) {
                    //if user session expired
                    debugger;
                    console.log('session expired for user');
                    //   Utils.showAlert(2, data.message);
                    setTimeout(() => {
                        // logOutApiCall();
                        if (sessionStorage.getItem('access_token') !== null) {
                            getAccessTokenUsingRefreshToken();
                        }
                    }, 1000);
                    // errorCallback(error.response);
                } else {
                    console.log('response: ', error.response);
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    console.log({ endPoint, params });
    Utils.constants.axios
        .patch(endPoint, params)
        .then((response: any) => {
            console.log('response: ', response);
            successCallback(response);
        })
        .catch((error: any) => {
            console.log('API Call error', error);
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    console.log('session expired for user');
                    //   Utils.showAlert(2, data.message);
                    setTimeout(() => {
                        if (sessionStorage.getItem('access_token') !== null) {
                            logOutApiCall();
                        }
                    }, 1000);
                    // errorCallback(error.response);
                } else {
                    console.log('response: ', error.response);
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const putApiCall = (endPoint: string, params: object, successCallback: Function, errorCallback: Function) => {
    console.log({ endPoint, params, successCallback });
    Utils.constants.axios
        .put(endPoint, params)
        .then((response: any) => {
            successCallback(response);
        })
        .catch((error: any) => {
            console.log('API Call error', error);
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;

                if (checkUserValidation(data)) {
                    //if user session expired
                    console.log('session expired for user');
                    //   Utils.showAlert(2, data.message);
                    setTimeout(() => {
                        if (sessionStorage.getItem('access_token') !== null) {
                            logOutApiCall();
                        }
                    }, 1000);
                    // errorCallback(error.response);
                } else {
                    console.log('response: ', error.response);
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

const deleteApiCall = (endPoint: string, params: string = '', successCallback: Function, errorCallback: Function) => {
    console.log({ endPoint, params });
    Utils.constants.axios
        .delete(Utils.constants.apiUrl + endPoint + params, {})
        .then((response: any) => {
            console.log(response);
            successCallback(response);
        })
        .catch((error: any) => {
            console.log('API Call error', error);
            if (error.code === 'ECONNABORTED') {
                let payload = {
                    data: {
                        statusCode: 408,
                    },
                };
                errorCallback(payload);
            } else if (error.response) {
                let data = error.response.data;
                if (checkUserValidation(data)) {
                    //if user session expired
                    console.log('session expired for user');
                    Utils.showAlert(2, data.message);
                    setTimeout(() => {
                        if (sessionStorage.getItem('access_token') !== null) {
                            logOutApiCall();
                        }
                    }, 1000);
                } else {
                    console.log('response: ', error.response);
                    errorCallback(error.response);
                }
            } else if (!error.response) {
                let payload = {
                    data: {
                        statusCode: '',
                        message: 'Please try again later',
                    },
                };
                errorCallback(payload);
            }
        });
};

/**
 * Logout API
 */
const logOutApiCall = () => {
    if (!navigator.onLine) {
        //check if user is online or not
        // Utils.showAlert(3, "Please check your internet connection!");
        return;
    }
    console.log('logout');
    Utils.constants.getAccessToken();
    Utils.constants.removeSession();
    window.location.replace(window.location.origin);
    //   Utils.constants.axios
    //     .patch(Utils.endPoints.logout, "")
    //     .then((response: any) => {
    //       Utils.constants.removeSession();
    //       window.location.replace(window.location.origin);
    //       console.log("logout success", response);
    //     })
    //     .catch((error: any) => {
    //       console.log("logout error", error);
    //     });

    Utils.constants.removeSession();
};
/**
 * export all function
 */
export default {
    putApiCall,
    getApiCall,
    postApiCall,
    patchApiCall,
    deleteApiCall,
    logOutApiCall,
    checkUserValidation,
    // getWordPressApiCall,
};
