// import Sessions from '../utils/constants';

class LoadingModel {
    isLoading: boolean = true;
    mainLoading: boolean = false;
}

class signInModel {
    email: string = '';
    password: string = '';
    uid: string = '';
}

class usersListModel {
    users: any = [];
    totalPages: any = 0;
    nextId: any = '';
    prevId: any = '';
    currentId: any = '';
    nextPage: boolean = false;
    prevPage: boolean = false;
    isLoading: boolean = true;
    sortType: string = 'asc';
    refreshId: string = '';
    searchQuery: string = '';
    transitionPage: string = '';
}

class subadminListModel {
    users: any = [];
    totalPages: any = 0;
    nextId: any = '';
    prevId: any = '';
    currentId: any = '';
    nextPage: boolean = false;
    prevPage: boolean = false;
    isLoading: boolean = true;
    sortType: string = 'asc';
    refreshId: string = '';
    searchQuery: string = '';
    transitionPage: string = '';
}

class sharedCrateDataModel {
    crateData: Object = {};
    crate_name: String = '';
    date_shared_seconds: number = 0;
    date_shared_nanoseconds: number = 0;
    crate_description: String = '';
    photosCount: number = 0;
    photos: any = [];
    notesCount: number = 0;
    notes: any = [];
    linksCount: number = 0;
    links: any = [];
}

class sharedItemDataModel {
    title: String = '';
    description: String = '';
    data: any;
    type: String = '';
}

class sharedCrateViewAllDataModel {
    data: any = [];
    nextPage: boolean = false;
    nextId: String = '';
}

class ReducersModel {
    globalLoaderReducer: LoadingModel = new LoadingModel();
    signInReducer: signInModel = new signInModel();
    usersListReducer: usersListModel = new usersListModel();
    subadminListReducer: subadminListModel = new subadminListModel();
    sharedCrateDataReducer: sharedCrateDataModel = new sharedCrateDataModel();
    sharedCrateViewAllDataReducer: sharedCrateViewAllDataModel = new sharedCrateViewAllDataModel();
    sharedItemDataReducer: sharedItemDataModel = new sharedItemDataModel();
}

export {
    signInModel,
    LoadingModel,
    ReducersModel,
    usersListModel,
    subadminListModel,
    sharedCrateDataModel,
    sharedCrateViewAllDataModel,
    sharedItemDataModel,
};
