import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            maxWidth: '74px',
            cursor: 'pointer',
        },
    }),
);

export default function Logo(props: any) {
    const classes = styles();

    const history = useHistory();

    return (
        <img
            onClick={() => {
                history.push('/dashboard');
            }}
            className={classes.logo}
            alt="logo"
            src="logo.png"
        />
    );
}
