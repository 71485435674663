import {
    // MuiThemeProvider,
    createMuiTheme,
} from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        secondary: {
            main: 'rgb(251, 242, 229)',
        },
        primary: {
            main: 'rgb(252, 182, 20)',
        },
        info: {
            main: 'rgb(75, 48, 88)',
        },
    },
    typography: {
        fontFamily: 'apercuproregular',
        button: {
            textTransform: 'none',
            boxShadow: '0 1px 0 1px rgba(0, 0, 0, 0.15)',
            fontFamily: 'apercupromedium',
        },
    },
});
