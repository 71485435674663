import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, Box, Container } from '@material-ui/core';
import './dashboard.css';

// import Pagination from '@material-ui/lab/Pagination';
import NoDataFound from '../../components/noDataFound/index';
import Spinner from '../../components/spinner/index';
import { useSelector, useDispatch } from 'react-redux';

import { ReducersModel } from '../../model';
import MenuItemComponent from '../../components/menuItemEdit/index';
import EditUserForm from './editUserForm';
import { showUsersList } from './action';
import Utils from '../../utils';
// import { showUsersList } from './action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            margin: '20px auto',
            padding: '10px',
            border: '1px solid #eee',
        },
        root: {},
        active: {
            backgroundColor: 'rgb(75, 48, 88)',
            color: '#ffffff',
        },

        paginationBox: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '& .Mui-selected': {
                backgroundColor: 'rgb(75, 48, 88)',
                color: 'rgb(251, 242, 229)!important',
                '&:hover': {
                    backgroundColor: 'rgb(75, 48, 88, 0.8)!important',
                },
            },

            '& .MuiPagination-ul': {
                '&> li': {
                    '& .MuiPaginationItem-root': {
                        border: '1px solid rgba(75, 48, 88, 0.53)',
                        color: 'rgb(75, 48, 88)',
                        margin: 0,
                        marginRight: '13px',
                    },
                },
            },
        },

        usersList: {
            minHeight: '580px',
        },
        pagination: {
            display: 'flex',
            marginTop: '50px',
            marginBottom: '30px',
            alignItems: 'center',
            justifyContent: 'center',
            '&>div': {
                margin: '0 8px',
            },
        },
        leftButton: {
            width: '21px',
            height: '19px',
            marginRight: '13px',
            cursor: 'pointer',
        },
        rightButton: {
            width: '21px',
            height: '19px',
            cursor: 'pointer',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        userEmail: {
            wordBreak: 'break-all',
        },
    }),
);

export default function UsersList({ history, className, ...props }: any) {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state: ReducersModel) => state.globalLoaderReducer);
    const { nextId, sortType, nextPage, prevPage, prevId, users, searchQuery } = useSelector(
        (state: ReducersModel) => state.usersListReducer,
    );
    // const { totalPages } = useSelector((state: ReducersModel) => state.usersListReducer);
    // let { data } = users;

    // for Pagination
    // const [page, setPage] = React.useState(1);
    // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    //     setPage(value);
    // };
    //

    // Edit user email or pass
    const [editUserClicked, setEditUserClicked] = useState({ email: '' });
    const changeEditUser = (user: any) => {
        setEditUserClicked({ ...editUserClicked, email: user });
    };

    const cancelEdit = () => {
        setEditUserClicked({ ...editUserClicked, email: '' });
    };

    // const previousPage = () => {
    //     if (page !== 1) {
    //         setPage(page - 1);
    //     }
    // };
    // const nextPage = () => {
    //     if (page < totalPages.total_pages) {
    //         setPage(page + 1);
    //     }
    // };

    // useEffect(() => {
    //     dispatch(showUsersList(`?limit=50`));
    //     // dispatch(showUsersListSample(`?page=${page}`));
    // }, [dispatch, page, changedCreds]);
    // useEffect(() => {

    // dispatch(showUsersListSample(`?page=${page}`));
    // }, [nextPage]);

    // useEffect(() => {
    // dispatch(showUsersList(`?limit=50`));
    // dispatch(showUsersListSample(`?page=${page}`));
    // }, [isLoading]);
    // const [open, setOpen] = React.useState(false);
    // const handleClose = () => {
    //     setOpen(false);
    // };

    const turnNextPage = () => {
        if (nextPage === true) {
            if (users !== null) {
                dispatch({
                    type: Utils.ActionName.CHANGE_CURRENT_ID,
                    payload: users[users.length - 1].email,
                });
                dispatch({
                    type: Utils.ActionName.CHANGE_TRANSITION_PAGE,
                    payload: 'next',
                });
            }

            if (searchQuery === '') {
                dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}`, nextId, sortType, 'next'));
            } else {
                dispatch(
                    showUsersList(
                        `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}`,
                        nextId,
                        sortType,
                        'next',
                    ),
                );
            }
        }
    };

    const turnPrevPage = () => {
        if (prevPage === true) {
            if (users !== null) {
                dispatch({
                    type: Utils.ActionName.CHANGE_CURRENT_ID,
                    payload: users[0].email,
                });
                dispatch({
                    type: Utils.ActionName.CHANGE_TRANSITION_PAGE,
                    payload: 'prev',
                });
            }

            if (searchQuery === '') {
                dispatch(showUsersList(`?limit=${Utils.constants.page_limit.limit}`, prevId, sortType, 'prev'));
            } else {
                dispatch(
                    showUsersList(
                        `?limit=${Utils.constants.page_limit.limit}&search=${searchQuery}`,
                        prevId,
                        sortType,
                        'prev',
                    ),
                );
            }

            // if(users!==null){

            // }
        }
    };

    const classes = styles();

    const listUsers = () => {
        if (isLoading === false) {
            if (props.users && props.users.length !== 0) {
                return props.users.map((user: any, index: any) => {
                    console.log(user.total_items);

                    return (
                        <Container
                            key={index}
                            className={`listBox ${editUserClicked.email === user.email ? 'edit_listBox' : ''}`}
                        >
                            {editUserClicked.email !== user.email ? (
                                <React.Fragment>
                                    <Box className={`item ${classes.userEmail}`}>{user.email}</Box>
                                    <Box className={'item'}>
                                        {user.total_items === undefined ? 0 : user.total_items} items
                                    </Box>
                                    <Box className={'itemIcon'}>
                                        <MenuItemComponent user={user} changeEditUser={changeEditUser} />
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <EditUserForm user={user} cancelEdit={cancelEdit} />
                            )}
                        </Container>
                    );
                });
            } else {
                return <NoDataFound />;
            }
        } else {
            return <Spinner />;
        }
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.usersList}>{listUsers()}</Box>

            <div className={classes.pagination}>
                <div>
                    <img
                        onClick={turnPrevPage}
                        src={'arrow-left.png'}
                        alt={'left-arrow'}
                        className={classes.leftButton}
                        style={prevPage === false ? { opacity: 0.35, cursor: 'not-allowed' } : {}}
                    />
                </div>
                {/* <div className={classes.paginationBox}>
                    <Pagination
                        count={totalPages.total_pages}
                        page={page}
                        onChange={handleChange}
                        hidePrevButton
                        hideNextButton
                    />
                </div> */}
                <div>
                    <img
                        onClick={turnNextPage}
                        src={'arrow-right.png'}
                        alt={'right-arrow'}
                        className={classes.rightButton}
                        style={nextPage === false ? { opacity: 0.35, cursor: 'not-allowed' } : {}}
                    />
                </div>
            </div>
        </Box>
    );
}
