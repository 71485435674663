export default {
    SIGN_IN: 'update_signin_val',
    LOADING: 'loading',
    ADD_USERS_LIST: 'ADD_USERS_LIST',
    ADD_USER_DATA: 'ADD_USER_DATA',
    SORT_DASHBOARD_LIST_ALPHABETICALLY: 'SORT_DASHBOARD_LIST_ALPHABETICALLY',
    SORT_DASHBOARD_LIST_REVERSE_ALPHABETICALLY: 'SORT_DASHBOARD_LIST_REVERSE_ALPHABETICALLY',
    ADD_SUBADMIN_LIST: 'ADD_SUBADMIN_LIST',
    IS_LOADING: 'IS_LOADING',
    CHANGE_SORT_TYPE: 'CHANGE_SORT_TYPE',
    CHANGE_CURRENT_ID: 'CHANGE_CURRENT_ID',
    CHANGE_NEXT_ID: 'CHANGE_NEXT_ID',
    CHANGE_PREV_ID: 'CHANGE_PREV_ID',
    CHANGE_NEXT_PAGE: 'CHANGE_NEXT_PAGE',
    CHANGE_PREV_PAGE: 'CHANGE_PREV_PAGE',
    CHANGE_SUBADMIN_SORT_TYPE: 'CHANGE_SUBADMIN_SORT_TYPE',
    CHANGE_SUBADMIN_CURRENT_ID: 'CHANGE_SUBADMIN_CURRENT_ID',
    CHANGE_SUBADMIN_NEXT_ID: 'CHANGE_SUBADMIN_NEXT_ID',
    CHANGE_SUBADMIN_PREV_ID: 'CHANGE_SUBADMIN_PREV_ID',
    CHANGE_SUBADMIN_NEXT_PAGE: 'CHANGE_SUBADMIN_NEXT_PAGE',
    CHANGE_SUBADMIN_PREV_PAGE: 'CHANGE_SUBADMIN_PREV_PAGE',
    CHANGE_SUBADMIN_REFRESH_ID: 'CHANGE_SUBADMIN_REFRESH_ID',
    CHANGE_REFRESH_ID: 'CHANGE_REFRESH_ID',
    CHANGE_SEARCH_QUERY: 'CHANGE_SEARCH_QUERY',
    CHANGE_SUBADMIN_SEARCH_QUERY: 'CHANGE_SUBADMIN_SEARCH_QUERY',
    CHANGE_TRANSITION_PAGE: 'CHANGE_TRANSITION_PAGE',
    CHANGE_SUBADMIN_TRANSITION_PAGE: 'CHANGE_SUBADMIN_TRANSITION_PAGE',
    ADD_SHARED_CRATE_DATA: 'ADD_SHARED_CRATE_DATA',
    ADD_SHARED_CRATE_VIEW_ALL_DATA: 'ADD_SHARED_CRATE_VIEW_ALL_DATA',
    ADD_SHARED_ITEM_DATA: 'ADD_SHARED_ITEM_DATA',
};
