import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Button,
    Box,
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './dashboard.css';

// import NoDataFound from '../../components/noDataFound/index';
// import { useSelector, useDispatch } from 'react-redux';
import { editUserInformation } from './action';

// import { ReducersModel } from '../../model';
import { Formik } from 'formik';
import Schema from '../../schema';
import {
    useDispatch,
    useSelector,
    //  useSelector
} from 'react-redux';
import { ReducersModel } from '../../model';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        mainContainer: {},
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            margin: '20px auto',
            padding: '10px',
            border: '1px solid #eee',
        },
        root: {},
        active: {
            backgroundColor: 'rgb(75, 48, 88)',
            color: '#ffffff',
        },

        paginationBox: {
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '50px',

            '& .Mui-selected': {
                backgroundColor: 'rgb(75, 48, 88)',
                color: 'rgb(251, 242, 229)!important',
                '&:hover': {
                    backgroundColor: 'rgb(75, 48, 88, 0.8)!important',
                },
            },

            '& .MuiPagination-ul': {
                '&> li': {
                    '& .MuiPaginationItem-root': {
                        border: '1px solid rgba(75, 48, 88, 0.53)',
                        color: 'rgb(75, 48, 88)',
                    },
                },
                '&> li:first-child': {
                    '& .MuiPaginationItem-root': {
                        border: 'none',
                        color: 'rgb(75, 48, 88)',
                    },
                },
                '&> li:last-child': {
                    '& .MuiPaginationItem-root': {
                        border: 'none',
                        color: 'rgb(75, 48, 88)',
                    },
                },
            },
        },

        usersList: {
            minHeight: '580px',
        },
        passwordIcon: {
            cursor: 'pointer',
        },
    }),
);

export default function UsersList({ history, className, ...props }: any) {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const { currentId, sortType, transitionPage, searchQuery, users } = useSelector(
        (state: ReducersModel) => state.usersListReducer,
    );
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    // Dialogue
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAlertClose = () => {
        setOpen(false);
    };

    const handleDialogueClose = () => {
        // setAnchorEl(null);
    };
    //

    const classes = styles();

    return (
        <React.Fragment>
            <Formik
                initialValues={{
                    email: props.user.email,
                    userId: props.user.user_id,
                    password: '',
                }}
                validationSchema={Schema.LoginSchema}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                    try {
                        dispatch(
                            editUserInformation(
                                values,
                                setSubmitting,
                                history,
                                currentId,
                                sortType,
                                transitionPage,
                                searchQuery,
                                users,
                            ),
                        );
                        handleClickOpen();
                        props.cancelEdit();
                    } catch (error) {
                        setStatus({ success: false });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form onSubmit={handleSubmit} className="edit">
                        <Box className={'item'}>
                            <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                // autoFocus
                                helperText={touched.email && errors.email}
                                placeholder="Email Address"
                                // margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                                size="small"
                            />
                        </Box>
                        <input type="hidden" name="userId" />

                        <Box className={'item'}>
                            <TextField
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                placeholder="New Password"
                                // margin="normal"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {showPassword ? (
                                                <Tooltip title={'Hide Password'} placement={'top'}>
                                                    <VisibilityOff
                                                        className={classes.passwordIcon}
                                                        onClick={togglePassword}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={'Show Password'} placement={'top'}>
                                                    <Visibility
                                                        className={classes.passwordIcon}
                                                        onClick={togglePassword}
                                                    />
                                                </Tooltip>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>

                        <Box className="cancel">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        onClick={props.cancelEdit}
                                        color="secondary"
                                        size="medium"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            // onClick={editUserInformation}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                )}
            </Formik>
            <Dialog
                open={open}
                onClose={handleDialogueClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="text_center"
            >
                <DialogTitle id="alert-dialog-title">{'Update User Credentials'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to update user credentials?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog_btn_center">
                    <Button
                        onClick={() => {
                            handleAlertClose();

                            props.cancelEdit();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleAlertClose} variant="contained" color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
