import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    createMuiTheme,
    ThemeProvider,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { deleteSubAdmin } from '../../screens/manageUsers/action';
import { ReducersModel } from '../../model';

const theme = createMuiTheme({
    palette: {
        primary: { main: 'rgb(223, 133, 0)', contrastText: '#000' },
    },
});

export default function DeleteSubAdmin({ ...props }: any) {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const classes = styles();

    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const dispatch = useDispatch();
    const { currentId, sortType, transitionPage, users } = useSelector(
        (state: ReducersModel) => state.subadminListReducer,
    );
    // Dialogue
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAlertClose = () => {
        setOpen(false);
    };
    //

    // let task = props.user.banned ? 'activate' : 'suspend';

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Button
                    className="delete_btn"
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="outlined"
                    onClick={() => {
                        handleClickOpen();
                    }}
                >
                    Delete
                </Button>
            </ThemeProvider>

            <Dialog
                open={open}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="text_center"
            >
                <DialogTitle id="alert-dialog-title">Delete sub admin</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to delete
                        {' ' + props.user.email + ' '}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog_btn_center">
                    <Button
                        onClick={() => {
                            handleAlertClose();
                            // setOpenSnackbar(true);
                            // console.log('called yes for delete');
                            dispatch(deleteSubAdmin(props.user.user_id, currentId, sortType, transitionPage, users));
                            // dispatch(editUserInformation(values, setSubmitting, history, currentId, sortType));
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleAlertClose} variant="contained" color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
