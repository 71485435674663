import * as Yup from 'yup';
// import Utils from '../utils';
//@ts-ignore
// import mapValues from 'lodash/mapValues';
// import moment from 'moment';
/**
 * @description
 * @param t language
 */
const LoginSchema = (t: any) =>
    Yup.object().shape({
        email: Yup.string()
            .trim()
            .email('Whoops! This email is not a valid format. Please check your entry and try again.')
            .required('Whoops! Email is required. Please check your entry and try again.')
            .max(50),
        password: Yup.string()
            .min(6, '"Whoops! That password is too short. Please stick to 6 characters and no spaces."')
            .max(20, '"Whoops! That password is too long. Please stick to 6-20 characters and no spaces."')
            .required('Password is required')
            .matches(/^\S*$/, 'Please stick to 6-20 characters and no spaces.'),
        // rememberMe: Yup.bool().oneOf([true], "Remember me required"),
        // recaptcha: Yup.string().required(t("CAPTCHA_REQ")),
    });

const AddAdminSchema = (t: any) =>
    Yup.object().shape({
        email: Yup.string()
            .trim()
            .email('Whoops! This email is not a valid format. Please check your entry and try again.')
            .required('Whoops! Email is required. Please check your entry and try again.')
            .max(50),
    });

const Schema = {
    LoginSchema,
    AddAdminSchema,
};

export default Schema;
