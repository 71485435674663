import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
const styles = makeStyles((theme: Theme) =>
    createStyles({
        no_data_wrapper: {
            maxWidth: '700px',
            minHeight: '250px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            flexDirection: 'column',
        },
        noDataFound: {
            fontSize: '40px',
            color: '#d4d4d4',
            marginTop: '20px',
        },
        noDataImg: {
            width: '120px',
        },
    }),
);

export default function Logo() {
    const classes = styles();

    return (
        <div className={classes.no_data_wrapper}>
            <img className={classes.noDataImg} src="no-data.png" alt="" />
            <h1 className={classes.noDataFound}>No data found</h1>
        </div>
    );
}
