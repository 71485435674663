import { combineReducers } from 'redux';

import { signInReducer } from '../screens/login/reducer';
import {
    // counterReducer,
    // sortDashboardList,
    usersListReducer,
    // usersloadingReducer,
} from '../screens/dashboard/reducer';
import {
    // counterReducer,
    // sortDashboardList,
    subadminListReducer,
} from '../screens/manageUsers/reducer';

import { sharedCrateDataReducer } from '../screens/sharedCrate/reducer';

import { sharedItemDataReducer } from '../screens/sharedItem/reducer';

import {
    // counterReducer,
    // sortDashboardList,
    sharedCrateViewAllDataReducer,
} from '../screens/viewAllMedia/reducer';

import { globalLoaderReducer } from './rootReducer';

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
    signInReducer,
    globalLoaderReducer,
    // counterReducer,
    usersListReducer,
    subadminListReducer,
    sharedCrateDataReducer,
    sharedCrateViewAllDataReducer,
    sharedItemDataReducer,
    // usersloadingReducer,
    // sortDashboardList,
});

export default rootReducer;
