import Utils from '../../utils';
import { sharedCrateDataModel } from '../../model';

// export const signInReducer = (state: signInModel = new signInModel(), action: any) => {
//     switch (action.type) {
//         case Utils.ActionName.SIGN_IN:
//             return { ...state, ...action.payload };
//         default:
//             return state;
//     }
// };

export const sharedCrateDataReducer = (state: sharedCrateDataModel = new sharedCrateDataModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ADD_SHARED_CRATE_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
