import Utils from '.';
// import { UserData } from "../modal";

/**
 * get current language from locale
 */
// export const getCurrentLanguage = () => {
//   return sessionStorage.getItem("i18nextLng");
// };

/**
 * set access token in axios instance header
 */
export const setAuthorizationToken = (token?: string) => {
    if (token) {
        // Utils.constants.axios.defaults.headers.common[
        //   "accesstoken"
        // ] = `bearer ${token}`;

        Utils.constants.axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    } else {
        Utils.constants.axios.defaults.headers.common['authorization'] = '';
        delete Utils.constants.axios.defaults.headers.common['authorization'];
    }
};

/**
 * set user session in browser
 */
export const setSession = (payload: any) => {
    for (var k in payload) {
        var isCurrentValObject = typeof payload[k] === 'object';
        if (isCurrentValObject && payload[k] !== null) {
            //if property is object then save it as string
            sessionStorage.setItem(k, JSON.stringify((payload as any)[k]));
        } else {
            sessionStorage.setItem(k, (payload as any)[k]);
        }
    }
    setAuthorizationToken(payload.access_token);
};

export const removeSession = () => {
    sessionStorage.clear();
    setAuthorizationToken('');
    window.location.reload();
};
/**
 * get user access token form loclastorage
 */
export const getAccessToken = () => {
    let accessToken = sessionStorage.getItem('access_token');
    if (accessToken) {
        setAuthorizationToken(accessToken);
    }

    return accessToken;
};

/**
 * get user access token form sessionStorage
 */
export const setAccessToken = (accessToken: any) => {
    if (accessToken) {
        setAuthorizationToken(accessToken);
    }
    return accessToken;
};
/**
 * get user id form sessionStorage
 */
export const getUserIdToken = () => {
    return sessionStorage.getItem('access_token');
};

export const manageUserSession = (history: any) => {
    let { access_token } = sessionStorage;
    if (access_token) {
        //push user directly to dashboard in app
    } else {
        //do nothing reset user here
    }
};
// export const getUserData = () => {
//   let toReturn: UserData = new UserData();
//   for (var k in UserData) {
//     (toReturn as any)[k] = sessionStorage.getItem(k);
//   }
//   return toReturn;
// };
