import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, Container } from '@material-ui/core';
// import Logo from '../logo/index';
import './header.css';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ReducersModel } from '../../model';
import Utils from '../../utils';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
            },
        },
        crateExpiry: {
            maxWidth: '50%',
            fontFamily: 'apercupromedium',
            fontWeight: 550,
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        logo: {
            maxWidth: '74px',
            // cursor: 'pointer',
        },
    }),
);

export default function Header(history: any) {
    const classes = styles();

    const { date_shared_seconds } = useSelector((state: ReducersModel) => state.sharedCrateDataReducer);
    const [days, setDays] = useState(0);
    // const [isTokenExpired, setIsTokenExpired]= useState(false);

    useEffect(() => {
        // const day= moment.unix(date_shared_seconds);
        const expiryDate = moment.unix(date_shared_seconds).add(Utils.constants.expiryDaysForShare, 'days');
        const dateToday = moment();
        setDays(expiryDate.diff(dateToday, 'days'));
    }, [date_shared_seconds]);

    return (
        <Container className={`header ${classes.container}`}>
            <img className={classes.logo} alt="logo" src="logo.png" />
            <div className={classes.crateExpiry}>{days >= 0 ? 'This link will expire in ' + days + ' days' : ''}</div>
        </Container>
    );
}
