import React, { Suspense } from 'react';
import Utils from '../utils';
import { Route, Redirect } from 'react-router-dom';
// import InnerContainer from "../components/pageContainer/InnerContainer";
import { Backdrop, CircularProgress } from '@material-ui/core';
import AdminLayout from '../components/adminLayout';
import AuthLayout from '../components/authLayout';
import ManageUsers from '../screens/manageUsers/index';
import Dashboard from '../screens/dashboard';

interface Props {
    path: string;
    component: any;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => (
    <Route
        {...rest}
        render={(props) => (
            <Suspense
                fallback={
                    <Backdrop
                        style={{
                            zIndex: 1,
                            color: '#fff',
                        }}
                        open={true}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            >
                {Utils.constants.getUserIdToken() ? (
                    //  <InnerContainer {...props} >

                    Component === <ManageUsers /> ? (
                        sessionStorage.user_type === 'admin' ? (
                            <AdminLayout {...props}>
                                <Component {...props} />
                            </AdminLayout>
                        ) : (
                            <AuthLayout {...props}>
                                <Dashboard />
                            </AuthLayout>
                        )
                    ) : (
                        <AdminLayout {...props}>
                            <Component {...props} />
                        </AdminLayout>
                    )
                ) : (
                    // </InnerContainer>
                    <Redirect to="/" />
                )}
            </Suspense>
        )}
    />
);
export default PrivateRoute;
