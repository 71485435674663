import React, { useState } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    TextField,
    FormHelperText,
    Box,
    Button,
    Typography,
    InputAdornment,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import Schema from '../../schema/index';
import { Formik } from 'formik';

import { editAdminInformation } from './action';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        apiError: {
            backgroundColor: 'transparent',
        },
        editFiend: {
            maxWidth: '354px',
            margin: '0 auto',
        },
        errorMail: {
            display: 'flex',
            justifyContent: 'center',
            color: 'darkslategrey',
        },
        closeButton: {
            position: 'absolute',
            top: '0',
            right: '0',
        },
        buttonWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            maxWidth: '354px',
            margin: '0 auto',
        },
        passwordIcon: {
            cursor: 'pointer',
        },
    }),
);

// Form for editing account information of admin user
export default function AccountInfoForm(props: any) {
    const classes = styles();

    const dispatch = useDispatch();

    // Toggle password input
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    //

    // Dialogue
    const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleAlertClose = () => {
        setOpen(false);
    };

    const handleDialogueClose = () => {
        // setAnchorEl(null);
    };
    //

    return (
        <React.Fragment>
            <Typography align="center" variant="h5" className="sub_heading">
                Edit Account Info
            </Typography>
            <Formik
                initialValues={{
                    email: sessionStorage.getItem('email_id'),
                    password: '',
                }}
                validationSchema={Schema.LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // try {
                    // alert(JSON.stringify(values, null, 2));
                    dispatch(editAdminInformation(values, setSubmitting, props.handleClose));
                    // setOpenSnackbar(true);
                    // handleClickOpen();
                    // } catch (error) {
                    //     // const message = (error.response && error.response.data.message) || 'Something went wrong';

                    //     setStatus({ success: false });
                    //     // setErrors({ submit: message });
                    //     setSubmitting(false);
                    //     console.log('Helllooo error from new admin submit');
                    // }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <React.Fragment>
                        <form className={classes.root} onSubmit={handleSubmit}>
                            <Box className={classes.editFiend}>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    placeholder="Email"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    className="bgWhiteInp newSubAdmin"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                    placeholder="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    value={values.password}
                                    fullWidth
                                    className="bgWhiteInp newSubAdmin"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" className="fade_icon">
                                                {showPassword ? (
                                                    <Tooltip title={'Hide Password'} placement={'top'}>
                                                        <VisibilityOff
                                                            className={classes.passwordIcon}
                                                            onClick={togglePassword}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={'Show Password'} placement={'top'}>
                                                        <Visibility
                                                            className={classes.passwordIcon}
                                                            onClick={togglePassword}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {/* <div className={classes.errorMail}>{sendInviteEmailError}</div> */}
                            </Box>

                            <Box className={classes.buttonWrap}>
                                <Button
                                    onClick={() => {
                                        // dialogCloseAddAdmin();
                                        // sendInvite();
                                        // console.log('hiii');
                                    }}
                                    className="small_btn"
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    Save
                                </Button>
                                <Box mt={3}>
                                    <FormHelperText className={classes.apiError} error>
                                        {/* {apiError} */}
                                    </FormHelperText>
                                </Box>
                            </Box>
                        </form>
                    </React.Fragment>
                )}
            </Formik>
            <Dialog
                open={open}
                onClose={handleDialogueClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="text_center"
            >
                <DialogTitle id="alert-dialog-title">{'Update Credentials'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to update your credentials?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog_btn_center">
                    <Button
                        onClick={() => {
                            handleAlertClose();
                            props.handleClose();
                            props.openSnackbar();
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleAlertClose} variant="contained" color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
