import Utils from '../../utils';
import {
    //  counterModel,
    usersListModel,
    // LoadingModel,
} from '../../model';

// export const counterReducer = (state: counterModel = new counterModel(), action: any) => {
//     switch (action.type) {
//         case Utils.ActionName.INC_COUNTER: {
//             return {
//                 ...state,
//                 counter: state.counter + 1,
//             };
//         }
//         case Utils.ActionName.DEC_COUNTER: {
//             return {
//                 ...state,
//                 counter: state.counter - 1,
//             };
//         }

//         default: {
//             return state;
//         }
//     }
// };

export const usersListReducer = (state: usersListModel = new usersListModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.ADD_USERS_LIST: {
            return {
                ...state,
                users: action.payload,
            };
        }
        case Utils.ActionName.ADD_USER_DATA: {
            return {
                ...state,
                totalPages: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SORT_TYPE: {
            return {
                ...state,
                sortType: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_CURRENT_ID: {
            return {
                ...state,
                currentId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_NEXT_ID: {
            return {
                ...state,
                nextId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_PREV_ID: {
            return {
                ...state,
                prevId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_NEXT_PAGE: {
            return {
                ...state,
                nextPage: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_PREV_PAGE: {
            return {
                ...state,
                prevPage: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_REFRESH_ID: {
            return {
                ...state,
                refreshId: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload,
            };
        }
        case Utils.ActionName.CHANGE_TRANSITION_PAGE: {
            return {
                ...state,
                transitionPage: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// export const usersloadingReducer = (state: LoadingModel = new LoadingModel(), action: any) => {
//     // console.log(action);

//     switch (action.type) {
//         case Utils.ActionName.IS_LOADING: {
//             return {
//                 ...state,
//                 isLoading: action.payload,
//             };
//         }

//         default: {
//             return state;
//         }
//     }
// };
