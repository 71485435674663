import React from 'react';
import Header from '../header';

// Higher order component to wrap children to with Header
export default function AuthContainer({ history, children }: any) {
    return (
        <div>
            <Header history={history} />
            {children}
        </div>
    );
}
