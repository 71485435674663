import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    createStyles,
    Theme,
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    Menu,
    MenuItem,
    Box,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowDropDownDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropDownUp from '@material-ui/icons/ArrowDropUp';
// import Pagination from '@material-ui/lab/Pagination';
// import { useSelector, useDispatch } from 'react-redux';
// import { increment, decrement } from './action';

// import { ReducersModal } from '../../modal';
import UsersList from './usersList';
import { useDispatch, useSelector } from 'react-redux';
import { showSubadminList, changeSortSubadminTypeOrder } from './action';
import { ReducersModel } from '../../model';
import { Helmet } from 'react-helmet';
import Utils from '../../utils';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        '@global': {
            body: {
                backgroundColor: 'rgb(255, 251, 244)',
            },
        },
        mainContainer: {},
        row: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '80%',
            margin: '20px auto',
            padding: '10px',
            border: '1px solid #eee',
        },
        root: {
            boxShadow: 'none',
        },
        activeAdmins: {
            marginBottom: '50px',
        },
    }),
);

export default function Dashboard({ history, className, ...rest }: any) {
    // const { counter } = useSelector((state: any) => ({ ...state.counterReducer }));

    // const { counter } = useSelector((state: ReducersModal) => state.counterReducer);

    // const dispatch = useDispatch();

    // const incCount = () => {
    //     dispatch(increment());
    // };
    const dispatch = useDispatch();
    const { users } = useSelector((state: ReducersModel) => state.subadminListReducer);

    // const decCount = () => {
    //     dispatch(decrement());
    // };
    useEffect(() => {
        // dispatch(showUsersList(`?page=${page}`));
        // dispatch(showSubadminList(`?limit=50`));
        dispatch(showSubadminList(`?limit=${Utils.constants.page_limit.limit}`));
        dispatch({
            type: Utils.ActionName.CHANGE_SUBADMIN_SORT_TYPE,
            payload: 'asc',
        });
        dispatch({ type: Utils.ActionName.CHANGE_CURRENT_ID, payload: '' });
        // dispatch(showUsersListSample(`?page=${page}`));
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Sorting state
    const [sortType, setSortType] = useState('A-Z');
    const changeSortType = (event: any) => {
        setSortType(event.target.innerText);
        handleClose();
    };

    const classes = styles();

    return (
        <React.Fragment>
            <Helmet>
                <title>Manage Users</title>
            </Helmet>

            <Box className="wrapper manageUsersBox">
                <Grid className={classes.root} container justify="space-between" spacing={3} {...rest}>
                    <Grid item>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        ></Breadcrumbs>
                        <Typography color="textPrimary" className="sub_heading">
                            Manage Users
                        </Typography>
                        <p className={`para ${classes.activeAdmins}`}>The following users are active admins on Rangl</p>
                    </Grid>
                </Grid>

                <Grid className={classes.root} container justify="space-between" spacing={3} {...rest}>
                    <Grid item>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            className="sorting"
                            // endIcon={<ArrowDropDownDown color="primary" style={{ fontSize: 35 }} />}
                            endIcon={
                                sortType === 'A-Z' ? (
                                    <ArrowDropDownDown color="primary" style={{ fontSize: 35 }} />
                                ) : (
                                    <ArrowDropDownUp color="primary" style={{ fontSize: 35 }} />
                                )
                            }
                        >
                            {sortType}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                value={'A-Z'}
                                onClick={(e: any) => {
                                    changeSortType(e);
                                    // sortAscendingOrder('?limit=50&sortOrder=asc');
                                    // dispatch(showSubadminList('?limit=50&sortOrder=asc'));
                                    dispatch(changeSortSubadminTypeOrder('asc'));
                                    dispatch(
                                        showSubadminList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=asc`),
                                    );
                                }}
                            >
                                A-Z
                            </MenuItem>
                            <MenuItem
                                value={'Z-A'}
                                onClick={(e: any) => {
                                    changeSortType(e);
                                    // sortDescendingOrder('?limit=50&sortOrder=desc');
                                    dispatch(changeSortSubadminTypeOrder('desc'));
                                    dispatch(
                                        showSubadminList(`?limit=${Utils.constants.page_limit.limit}&sortOrder=desc`),
                                    );
                                }}
                            >
                                Z-A
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <UsersList users={users} />
                </Box>
            </Box>
        </React.Fragment>
    );
}
