import Utils from '../../utils';
// import { batch } from "react-redux";

export const inviteSubAdmin = (values: any, setSubmitting: any, props: any) => {
    return (dispatch: Function) => {
        const { email } = values;
        const dataToSend = {
            email,
        };
        Utils.api.postApiCall(
            Utils.endPoints.inviteSubAdmin,
            dataToSend,
            (respData: any) => {
                let { data } = respData;
                setSubmitting(false);
                // console.log(data);
                if (data.statusCode === Utils.constants.api_success_code.success) {
                    // dispatch({
                    //     type: Utils.ActionName.SIGN_IN,
                    //     payload: {
                    //         // socialNumberVerify: false,
                    //         email: dataToSend.email,
                    //         apiError: '',
                    //     },
                    // });

                    // setTimeout(() => {
                    props.onCloseBox();
                    // }, 500);
                    // setOpenSnackbar(true);
                    // props.openSnackbar('Email Invitation has been sent to the user.');
                    Utils.showAlert(1, 'Email invitation has been sent to the user.');

                    // sessionStorage.setItem('header_code', data.headerCode);
                    // if (data.headerCode === Utils.constants.api_header_code.alreadyEnabled2fa) {
                    //     sessionStorage.setItem('verify_token', data.token);
                    //     dispatch({
                    //         type: Utils.ActionName.UPDATE_OTP_VAL,
                    //         payload: { token: data.token },
                    //     });
                    //     history.push(Utils.constants.paths.VERIFY_F_A);
                    // } else if (data.headerCode === Utils.constants.api_header_code.phoneVerification) {
                    //     sessionStorage.setItem('verify_token', data.token);
                    //     history.push(Utils.constants.paths.PHONE_VERIFY);
                    // } else {
                    // sessionStorage.setItem('access_token', data.token);
                    // history.push(Utils.constants.paths.DASHBOARD);
                    // dispatch(enable2FA(history));
                    // }
                } else {
                    setSubmitting(false);
                    //   Utils.showAlert(2);
                    // props.openSnackbar('Email address already in use.');
                    Utils.showAlert(1, data.message);
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;
                // Utils.showAlert(2, data.message);
                // dispatch({
                //     type: Utils.ActionName.SIGN_IN,
                //     payload: {
                //         apiError: data.error,
                //     },
                // });
                Utils.showAlert(1, data.message);
            },
        );
    };
};

export const editAdminInformation = (values: any, setSubmitting: any, closeDialogue: any) => {
    return (dispatch: Function) => {
        const { email, password } = values;
        const dataToSend = {
            email,
            password,
        };
        Utils.api.putApiCall(
            `admin/profile`,
            dataToSend,
            (respData: any) => {
                let { data } = respData;

                if (data.statusCode === Utils.constants.api_success_code.success) {
                    Utils.showAlert(1, 'Your credentials has been updated successfully!');

                    // dispatch(showUsersList(`?limit=50`));
                    closeDialogue();
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 100);

                    // sessionStorage.setItem('header_code', data.headerCode);
                    sessionStorage.setItem('email_id', email);
                    dispatch({
                        type: Utils.ActionName.SIGN_IN,
                        payload: {
                            // socialNumberVerify: false,
                            email: email,
                        },
                    });
                    // window.location.reload();
                } else {
                    setSubmitting(false);
                    Utils.showAlert(1, data.message);
                }
            },
            (error: any) => {
                setSubmitting(false);
                let { data } = error;

                // if (data.statusCode == Utils.constants.api_success_code.success) {
                if (data.statusCode === 400) {
                    Utils.showAlert(1, data.message);
                }

                // }
            },
        );
    };
};

export const logoutAdmin = () => {
    sessionStorage.clear();
    sessionStorage.clear();
    Utils.constants.setAuthorizationToken();
    // history.push('/');
    window.location.reload();
    // window.location.origin;
};
