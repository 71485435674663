import Utils from '../../utils';
import { signInModel } from '../../model';

export const signInReducer = (state: signInModel = new signInModel(), action: any) => {
    switch (action.type) {
        case Utils.ActionName.SIGN_IN:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
