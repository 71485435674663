import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import {
    MenuItem,
    IconButton,
    Menu,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';
import { activateAndSuspend } from '../../screens/dashboard/action';
import { useDispatch, useSelector } from 'react-redux';
import { ReducersModel } from '../../model';

export default function MenuItemComponent({ ...props }: any) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dispatch = useDispatch();

    // snackbar
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const handleCloseSnackbar = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };
    // snackbar

    // Dialogue
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAlertClose = () => {
        setOpen(false);
    };
    //
    const { currentId, sortType, transitionPage, searchQuery } = useSelector(
        (state: ReducersModel) => state.usersListReducer,
    );

    let task = props.user.banned ? 'activate' : 'suspend';

    return (
        <React.Fragment>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(252, 182, 20)',
                        minWidth: '199px',
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleClickOpen();
                        // setOpenSnackbar(true);
                    }}
                >
                    {props.user.banned ? 'Activate' : 'Suspend'}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        // console.log(user.email);
                        props.changeEditUser(props.user.email);
                    }}
                    // disabled edit if user has social account
                    disabled={props.user.is_google_login || props.user.is_apple_login}
                >
                    Edit
                </MenuItem>
            </Menu>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={'User account is successfully suspended!'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />

            <Dialog
                open={open}
                onClose={handleAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="text_center"
            >
                <DialogTitle id="alert-dialog-title">{`${
                    props.user.banned ? 'Activate' : 'Suspend'
                } User`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, you want to {props.user.banned ? 'activate' : 'suspend'} this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="dialog_btn_center">
                    <Button
                        onClick={() => {
                            handleAlertClose();
                            // setOpenSnackbar(true);

                            dispatch(
                                activateAndSuspend(
                                    task,
                                    props.user.user_id,
                                    currentId,
                                    sortType,
                                    transitionPage,
                                    searchQuery,
                                ),
                            );
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={handleAlertClose} variant="contained" color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
