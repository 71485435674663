import Utils from '../../utils';
import { sharedCrateViewAllDataModel } from '../../model';

// export const signInReducer = (state: signInModel = new signInModel(), action: any) => {
//     switch (action.type) {
//         case Utils.ActionName.SIGN_IN:
//             return { ...state, ...action.payload };
//         default:
//             return state;
//     }
// };
export const sharedCrateViewAllDataReducer = (
    state: sharedCrateViewAllDataModel = new sharedCrateViewAllDataModel(),
    action: any,
) => {
    switch (action.type) {
        case Utils.ActionName.ADD_SHARED_CRATE_VIEW_ALL_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
